define("discourse/plugins/discourse-surveys/initializers/extend-for-survey", ["exports", "@ember/object", "jquery", "discourse/lib/plugin-api", "discourse/widgets/glue", "discourse-common/lib/get-owner", "discourse-common/utils/decorators"], function (_exports, _object, _jquery, _pluginApi, _glue, _getOwner, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeSurveys(api) {
    const register = (0, _getOwner.getRegister)(api);
    api.modifyClass("controller:topic", {
      pluginId: "discourse-surveys",
      subscribe() {
        this._super(...arguments);
        this.messageBus.subscribe("/surveys/" + this.get("model.id"), msg => {
          const post = this.get("model.postStream").findLoadedPost(msg.post_id);
          if (post) {
            post.set("surveys", msg.surveys);
          }
        });
      },
      unsubscribe() {
        this.messageBus.unsubscribe("/surveys/*");
        this._super(...arguments);
      }
    });
    let _glued = [];
    let _interval = null;
    function rerender() {
      _glued.forEach(g => g.queueRerender());
    }
    api.modifyClass("model:post", dt7948.p({
      pluginId: "discourse-surveys",
      _surveys: null,
      surveysObject: null,
      surveysChanged() {
        const surveys = this.surveys;
        if (surveys) {
          this._surveys = this._surveys || {};
          surveys.forEach(p => {
            const existing = this._surveys[p.name];
            if (existing) {
              this._surveys[p.name].setProperties(p);
            } else {
              this._surveys[p.name] = _object.default.create(p);
            }
          });
          this.set("surveysObject", this._surveys);
          rerender();
        }
      }
    }, [["method", "surveysChanged", [(0, _decorators.observes)("surveys")]]]));
    function attachSurveys($elem, helper) {
      const $surveys = (0, _jquery.default)(".survey", $elem);
      if (!$surveys.length || !helper) {
        return;
      }
      let post = helper.getModel();
      api.preventCloak(post.id);
      post.surveysChanged();
      const surveys = post.surveysObject || {};
      _interval = _interval || setInterval(rerender, 30000);
      $surveys.each((idx, surveyElem) => {
        const $survey = (0, _jquery.default)(surveyElem);
        const surveyName = $survey.data("survey-name");
        let survey = surveys[surveyName];
        if (survey) {
          const attrs = {
            id: `${surveyName}-${post.id}`,
            post,
            survey,
            response: {}
          };
          const glue = new _glue.default("discourse-survey", register, attrs);
          glue.appendTo(surveyElem);
          _glued.push(glue);
        }
      });
    }
    function cleanUpSurveys() {
      if (_interval) {
        clearInterval(_interval);
        _interval = null;
      }
      _glued.forEach(g => g.cleanUp());
      _glued = [];
    }
    api.decorateCooked(attachSurveys, {
      onlyStream: true,
      id: "discourse-survey"
    });
    api.cleanupStream(cleanUpSurveys);
  }
  var _default = _exports.default = {
    name: "extend-for-survey",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", initializeSurveys);
    }
  };
});